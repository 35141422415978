(function() {
    document.addEventListener('click', function(e) {
        const accordionToggle = e.target.closest('.js-accordionToggle');
        if (!accordionToggle) return;
        e.preventDefault();
        handleAccordionToggle(accordionToggle);
    });

    const handleAccordionToggle = (target) => {
        const accordionContent = target.querySelector('.accordion-content');

        if (target.classList.contains('is-active')) {
            collapseAccordion(target, accordionContent);
            return;
        }

        collapseAllAccordions(target.closest('.accordion'));
        expandAccordion(target, accordionContent);
    };

    const collapseAccordion = (target, content) => {
        target.classList.remove('is-active');
        content.style.height = `${content.scrollHeight}px`;
        requestAnimationFrame(() => {
            content.style.height = '0';
        });
    };

    const expandAccordion = (target, content) => {
        target.classList.add('is-active');
        content.style.height = `${content.scrollHeight}px`;
        content.addEventListener('transitionend', () => {
            if (target.classList.contains('is-active')) {
                content.style.height = 'auto';
            }
        }, { once: true });
    };

    const collapseAllAccordions = (accordionContainer) => {
        const activeElements = accordionContainer.querySelectorAll('.js-accordionToggle.is-active');
        activeElements.forEach(element => {
            const content = element.querySelector('.accordion-content');
            collapseAccordion(element, content);
        });
    };

    const initializeAccordions = () => {
        document.querySelectorAll('.accordion-item').forEach(item => {
            const accordionContent = item.querySelector('.accordion-content');

            if (item.classList.contains('is-active')) {
                accordionContent.style.height = `${accordionContent.scrollHeight}px`;
                return;
            }

            accordionContent.style.height = '0';
        });
    };

    initializeAccordions();
})();
